import { TWidget } from 'widgets/Widget';

type refElementInstance = InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>;

/**
 * @param Widget Base widget for extending
 * @returns AddressList widget
 */
export default function (Widget: TWidget) {
    class OrderCancel extends Widget {
        cancelLink(link : refElementInstance) {
            const orderID = link.data('order-id') ? link.data('order-id') : '';

            this.eventBus().emit('account.order.cancel.step1', orderID);
        }
    }

    return OrderCancel;
}
