/* eslint-disable no-restricted-globals */
import { timeout } from 'widgets/toolbox/util';

/**
 * @description Disclosure component extension
 * @param Disclosure Base widget for extending
 * @returns Disclosure widget
 */
export default function (Disclosure: ReturnType<typeof import('widgets/global/Disclosure').default>) {
    /**
     * @category widgets
     * @subcategory global
     * @class DisclosureExt
     * @augments Disclosure
     * @classdesc Process required checkboxes
     */
    class DisclosureExt extends Disclosure {
        /**
         * @param event event
         * @description Toggles disclosure content and check if product Back in stock is Variant
         * @listens dom#click
         */
        // eslint-disable-next-line consistent-return
        toggleContent(): Promise<null> | undefined {
            const target = event!.currentTarget as HTMLButtonElement;

            // eslint-disable-next-line no-restricted-globals
            if (target?.id === 'notifyMe' && target?.dataset?.readyToNotify !== 'variant') {
                this.showCartMessage(target?.dataset?.textSelectOptions as string, true);

                return Promise.resolve(null);
            } else {
                super.toggleContent();
            }

            timeout(() => this.eventBus().emit('highlighter.update'), 200);
        }

        /**
         * @description Show message
         * @param msg Message
         * @param error Error flag
         */
        showCartMessage(msg: string, error = false) {
            this.getById('addToCartMsg', (addToCartMsg) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                if (typeof this.spacesLength === 'undefined') {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                    this.spacesLength = 0;
                }
                // It's needed to force render previously added success message. If we try to add the same product
                // several times, success message alert will not be announced by a screen reader.

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                msg = msg.concat(Array(++this.spacesLength).join(this.prefs().zeroWidthSpaceSymbol));

                addToCartMsg.render('template', { msg, error }, addToCartMsg.ref('container')).then(() => addToCartMsg.show());
            });
        }
    }

    return DisclosureExt;
}
