/**
 * @param AjaxForm Base widget for extending
 * @returns BackInStock widget
 */
import { timeout } from 'widgets/toolbox/util';
type IInput = InstanceType<ReturnType<typeof import('widgets/forms/BasicInput').default>>;

export default function (AjaxForm: ReturnType<typeof import('widgets/forms/AjaxForm').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class BackInStock
     * @augments AjaxForm
     * @classdesc Represents BackInStock component with next features:
     * 1. Updates product id of the product available for back in stock
     *
     * @property {string} data-widget - Widget name `backInStock`
     * @example <caption>Example of BackInStock widget usage</caption>
     *  <form>
     *      ...
     *      <input
     *          type="hidden"
     *          data-widget="inputHidden"
     *          data-ref="field"
     *          data-id="backInStockProductId"
     *          value="${product.id}"
     *          name="${pdict.backInStockForm.productID.htmlName}"
     *      />
     *      ...
     *  /form>
     */
    class BackInStock extends AjaxForm {
        updateProductId(productId) {
            this.getById('backInStockProductId', (backInStockProductId) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'setValue' does not exist on type 'Widget... Remove this comment to see the full error message
                backInStockProductId.setValue(productId);
            });
        }

        updateProductSize(productSize) {
            this.getById('backInStockProductSize', (backInStockProductSize) => {
                if (productSize) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setValue' does not exist on type 'Widget... Remove this comment to see the full error message
                    backInStockProductSize.setValue(productSize.value);
                }
            });
        }

        /**
         * @description Handles server response
         * @emits AjaxForm#submit
         * @param data Server JSON response once form submitted
         * @param [data.success] - If form submission was success
         * @param [data.redirectUrl] - if not empty - redirect to specified location should be executed
         * @param [data.error] - error messages/message from server
         * @param [data.fields] - form fields with errors
         */
        // eslint-disable-next-line sonarjs/cognitive-complexity
        onSubmitted(data): void {
            if (data.success && data.redirectUrl) {
                window.location.assign(data.redirectUrl);
            } else if (data.error) {
                if (data.redirectUrl) {
                    window.location.assign(data.redirectUrl);
                } else {
                    this.setError(Array.isArray(data.error) ? data.error.join('<br/>') : data.error);
                }
            } else if (data.fields) {
                Object.entries(data.fields).forEach(([name, errorMsg], index) => {
                    this.getById(name, (input: IInput) => {
                        input.setError(<string>errorMsg);

                        // Set focus to the first invalid input field
                        if (index === 0) {
                            input.setFocus();
                        }
                    });
                });
            } else {
                timeout(() => {
                    const productID = this.items.find(item => item && item.id === 'backInStockProductId');
                    const productSize = this.items.find(item => item && item.id === 'backInStockProductSize');

                    this.eventBus().emit('backinstock.product', { productID: productID, productSize: productSize });
                    /**
                     * @description Event to submit AjaxForm
                     * @event AjaxForm#submit
                     */
                    this.emit('submit', data);
                });
            }
        }
    }

    return BackInStock;
}
