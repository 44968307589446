import { TWidget } from 'widgets/Widget';
// TODO: JSDoc for all methods
// TODO: should be implemented as https://www.w3.org/TR/wai-aria-practices/#Listbox
// single select controls group (single tab stop element) with activation on click/space/enter
import { RefElement } from 'widgets/toolbox/RefElement';
type BasicInput = InstanceType<ReturnType<typeof import('widgets/forms/BasicInput').default>>;

/**
 * @description Base ColorSwatch implementation
 * @param Widget Base widget for extending
 * @returns Color Swatch widget
 */
export default function (Widget: TWidget) {
    /**
     * @class ColorSwatch
     * @augments Widget
     */
    class ColorSwatch extends Widget {
        selectColor(option) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedOption' does not exist on type '... Remove this comment to see the full error message
            this.selectedOption = option;
            this.emit('change', this);
        }

        getSelectedOptions() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedOption' does not exist on type '... Remove this comment to see the full error message
            if (this.selectedOption) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedOption' does not exist on type '... Remove this comment to see the full error message
                return this.selectedOption;
            }

            return new RefElement([]);
        }

        /**
         * @description Get input value
         * @param id - data-id
         * @returns input value
         */
        getInputValueByID(id: string) : string {
            let value = '';

            this.getById(id, (textArea: BasicInput) => {
                value = textArea.getValue();
            });

            return value;
        }

        /**
         * @description Validate input and return its validation result
         * @param id  data-id
         * @returns Input validation result
         */
        validateInputByID(id : string) : boolean {
            let isValid = false;

            this.getById(id, (textArea: BasicInput) => {
                isValid = textArea.validate();
            });

            return isValid;
        }
    }

    return ColorSwatch;
}
