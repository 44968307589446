const keyCode = Object.freeze({
    SPACE: 32,
    ENTER: 13
});

import { TWidget } from 'widgets/Widget';
import { RefElement } from 'widgets/toolbox/RefElement';

/**
 * @param Widget Base widget for extending
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Video
     * @augments Widget
     * @classdesc Represents Video component with autostart. Allows watching video after pressing "Video" thumbnail.
     *
     * @property {string} data-widget - Widget name `video`
     * @example <caption>Example of Carousel widget usage</caption>
     * <iframe
     *      class="b-product_gallery-video"
     *      data-ref="elemVideo"
     *      src="https://www.youtube.com/embed/pZWiOO5N-vI?enablejsapi=1&controls=0&modestbranding=1&playsinline=1"
     *      frameborder="2"
     *      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
     *      allowfullscreen
     *      hidden="hidden"
     * ></iframe>
     */

    class ProductVideo extends Widget {
        prefs() {
            return {
                videoThumbnailActiveClass: 'm-current',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();
        }

        /**
         * @description Removes activated styling from the video thumbnail
         */
        hideVideo(): void {
            this.ref('self').removeClass(this.prefs().videoThumbnailActiveClass);
        }

        /**
         * @description Add an activated styling to the video thumbnail and start to show video
         * @param el - event source element
         * @emits "showvideo"
         */
        showVideo(el: RefElement): void {
            el.addClass(this.prefs().videoThumbnailActiveClass);
            this.emit('showvideo');
        }

        /**
         * @param el - event source element
         * @param event - event instance if DOM event
         * @emits "showvideo"
         */
        handleKeydown(el: RefElement, event: KeyboardEvent): void {
            if (!event) {
                return;
            }

            if (event.keyCode === keyCode.ENTER || event.keyCode === keyCode.SPACE) {
                event.preventDefault();
                event.stopPropagation();

                this.showVideo(el);
            }
        }
    }

    return ProductVideo;
}
