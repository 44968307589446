import { RefElement } from 'widgets/toolbox/RefElement';

type IVideo = InstanceType<ReturnType<typeof import('widgets/product/ProductVideo').default>>;
type ICarousel = InstanceType<ReturnType<typeof import('widgets/global/Carousel').default>>;

/**
 * @description Base ProductVideoMixin implementation
 * @param ProductImages Base widget for extending
 */
export default function (ProductImages: ReturnType<typeof import('widgets/product/ProductImages').default>) {
    /**
     * @class ProductVideoMixin
     * @augments ProductImages
     * @subcategory product
     * @classdesc Represents product video with the next features:
     * 1. Show video element and start to play the video when the video thumbnail is clicked
     * 2. Hide video element and stop the video to when an image thumbnail is clicked
     */
    class ProductVideoMixin extends ProductImages {
        /**
         * @description Show video element and start to play the video when the video thumbnail is clicked
         */
        showVideoElement(): void {
            this.getById('imagesCarousel', (carousel) => {
                const videoSrc = carousel.ref('elemVideoWrapper').data('src');

                carousel.ref('elemVideo').attr('src', videoSrc);
                carousel.ref('elemVideoWrapper').show().focus();
            });

            this.getById<ICarousel>('imagesThumbnails', (carousel) => {
                carousel.markCurrentPage(-1);
            });
        }

        /**
         * @description Hide video element and stop the video to when an image thumbnail is clicked
         */
        hideVideoElement(): void {
            this.getById<IVideo>('videoThumbnail', (videoThumbnail) => {
                videoThumbnail.hideVideo();
            });

            this.getById('imagesCarousel', (carousel) => {
                carousel.ref('elemVideoWrapper').hide();
                carousel.ref('elemVideo').attr('src', '');
            });
        }

        /**
         * @description Hide video element when image thumbnail is clicked
         * @param el - event source element
         * @param page - current page number
         */
        onThumbnailCarouselPageClicked(el: RefElement, page: number): void {
            super.onThumbnailCarouselPageClicked(el, page);

            this.hideVideoElement();
        }

        /**
         * @description Hide video element when clicking on image thumbnail or selecting thumbnail from the keyboard
         * @param el - event source element
         * @param page - current page number
         */
        onImageCarouselPageChanged(el: RefElement, page: number) {
            super.onImageCarouselPageChanged(el, page);

            this.hideVideoElement();
        }
    }

    return ProductVideoMixin;
}
