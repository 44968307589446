import { submitFormJson } from 'widgets/toolbox/ajax';

type TProcessButtonInstance = InstanceType<ReturnType<typeof import('widgets/global/ProcessButton').default>>;

/**
 * @description Updated ProductDetail implementation for Quick View modal
 * @param {ProductDetail} ProductDetail Base widget for extending
 * @returns {typeof QuickView} Quick View widget
 */
export default function (ProductDetail: ReturnType<typeof import('widgets/product/ProductDetail').default>) {
    /**
     * @class QuickView
     * @augments ProductDetailBase
     */
    class QuickView extends ProductDetail {
        /**
         * @description Widget initialization
         * @returns {void}
         */
        init():void {
            super.init();

            this.eventBus().on('product.updated', 'onProductUpdated');
        }

        /**
         * @description Handles product.updated event
         * @param {object} response Response object
         * @returns {void}
         */
        onProductUpdated(response: any): void {
            if (response.error) {
                this.showErrorMessage(response.message || response.errorMessage, response.error);
            }
        }

        /**
         * @description Shows error message
         * @param msg Message
         * @param error Error flag
         * @returns {void}
         */
        showErrorMessage(msg: string, error = false): void {
            this.getById(this.prefs().addToCartMsg, (addToCartMsg) => {
                addToCartMsg.render('template', { msg, error }, addToCartMsg.ref('container')).then(() => addToCartMsg.show());
            });
        }

        /**
         * @description Updates Product from quick view modal
         * @param {object} updateBtn - button object
         * @returns {Promise<object>} Promise object represents server response for product updating
         */
        updateProduct(updateBtn: TProcessButtonInstance) {
            return super.updateProduct(updateBtn).then((response) => {
                if (!response) {
                    return Promise.resolve(null);
                }

                if (response.error) {
                    return response;
                }

                this.emit('updated');
                this.eventBus().emit('account.wishlist.update', updateBtn.config.pid);

                return response;
            });
        }

        /**
         * @description Processing once the promise with server response is settled
         * @emits "updated"
         * @returns {void}
         */
        onFinallyProductUpdated() {
        }
    }

    return QuickView;
}
