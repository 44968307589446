import { IRefElement } from 'widgets/toolbox/RefElement';
import { timeout } from 'widgets/toolbox/util';

type ICarousel = InstanceType<ReturnType<typeof import('widgets/global/Carousel').default>>;

const keyCode = Object.freeze({
    SPACE: 32,
    ENTER: 13
});

const IMAGE_MODE_CAROUSEL = 'carousel';
const IMAGE_MODE_ZOOM = 'zoom';

/**
 * @description Base ProductImages implementation
 * @param AccessibilityFocusTrapMixin mixin
 * @returns ProductImages widget
 */
export default function (
    AccessibilityFocusTrapMixin: ReturnType<typeof import('widgets/global/AccessibilityFocusTrapMixin').default>
) {
    /**
     * @class ProductImages
     * @augments AccessibilityFocusTrapMixin
     * @classdesc Basic ProductImages Widget, manages two images carousels: thumbnails and images.
     * <br>Renders images from server response and update carousels states accordingly.
     * <br>Listens to carousel scroll/click events to coordinate consistent work of two carousels.
     * <br>Serves image `zoom` popup functionality using `photoswipe` as a library
     * @property {string} data-widget - Widget name `productImages`
     * @property {string} data-thumbnails-per-frame - Number of thumbnails fully visible per frame
     * @property {string} data-thumbnails-zoom-class - Class of the thumbnails carousel, when zoom is opened
     * @property {string} data-zoom-loop - Zoom carousel needs to be looped or not
     * @property {string} data-zoom-click-to-close-non-zoomable - Close or not zoom popup for not zoomable elements
     * @property {string} data-zoom-close-el-classes - Closable zoom popup classes. For ex. `item,caption,zoom-wrap,ui,top-bar`
     * @property {boolean} closeOnScroll - Close gallery on page scroll
     * @property {boolean} showHideOpacity - Animate background opacity and image scale
     * @example
     * // use this code to display widget
     * <div
     *     data-widget="productImages"
     *     data-thumbnails-per-frame="4"
     * >
     *     <div
     *         class="b-product_gallery"
     *         data-ref="carouselInner"
     *     >
     *         <div
     *             class="b-product_gallery-thumbs"
     *             data-widget="carousel"
     *             id="imagesThumbnails"
     *             ....
     *             data-widget-event-pageclicked="onThumbnailCarouselPageClicked"
     *         >...</div>
     *         <div
     *             class="b-product_gallery-main b-product_slider"
     *             data-widget="carousel"
     *             id="imagesCarousel"
     *             ....
     *             data-widget-event-pagechanged="onImageCarouselPageChanged"
     *         >...</div>
     *     </div>
     *     <script type="template/mustache" data-ref="galleryTemplate">
     *         <div
     *             class="b-product_gallery"
     *             data-ref="carouselInner"
     *         >
     *             ... mustache carousels templates
     *         </div>
     *     </script>
     * </div>
     */
    class ProductImages extends AccessibilityFocusTrapMixin {
        private gallery: typeof import('photoswipe');

        private backFocusElement: HTMLElement | undefined;

        private imageMode: string | undefined;

        private longWaitingTimeout?: () => void | undefined;

        prefs() {
            return {
                accessibilityAlerts: <TAccessibilityAlerts>{},
                thumbnailsPerFrame: 4,
                thumbnailsZoomClass: 'm-zoomed-in',
                classesGlobalDialog: 'm-has_dialog',
                zoomLoop: false,
                zoomClickToCloseNonZoomable: false,
                zoomCloseElClasses: '',
                closeOnScroll: false,
                predefinedHeight: 1773,
                predefinedWidth: 1333,
                barsSizeBottom: 'auto',
                barsSizeTop: 24,
                showHideOpacity: true,
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.onDestroy(() => {
                if (this.gallery) {
                    this.gallery.close();
                    this.gallery.destroy();
                    this.gallery = undefined;
                }
            });
        }

        /**
         * @description Render product images
         * @param product - product object
         * @returns Promise object represents product images rendering result
         */
        renderImages(product): Promise<void|null> {
            if (product.images && product.images.large) {
                product.images.large.forEach(element => {
                    const zoomImage = product.zoomImages.zoom && product.zoomImages.zoom[element.index];

                    element.zoomUrl = zoomImage && zoomImage.url;
                    element.isSecond = parseInt(element.index, 10) === 1;
                });

                if (product.imageVideo && product.videoUrl) {
                    const newArraySmall = [...product.images.small];
                    const newElement = {
                        sources: [],
                        alt: newArraySmall[0].alt || '',
                        url: product.imageVideo,
                        index: '1',
                        isFirstImage: false,
                        title: newArraySmall[0].alt || '',
                        absURL: product.imageVideo,
                        empty: false,
                        isImageVideo: true
                    };

                    newArraySmall.forEach(item => {
                        if (parseInt(item.index, 10) >= 1) {
                            item.index = (parseInt(item.index, 10) + 1).toString();
                        }
                    });

                    newArraySmall.unshift(newElement);

                    newArraySmall.sort((a, b) => parseInt(a.index, 10) - parseInt(b.index, 10));
                    product.images.small = newArraySmall;
                }

                return this.render('galleryTemplate', product,
                    this.ref('carouselInner')).then(() => {
                    this.update();
                });
            } else {
                return Promise.resolve(null);
            }
        }

        /**
         * @description Handler to sync main carousel with thumbnails carousel
         * @param el - event source element
         * @param page - current page number
         */
        onImageCarouselPageChanged(el: IRefElement, page: number): void {
            this.getById('imagesThumbnails', (carousel: ICarousel) => {
                if (this.imageMode !== IMAGE_MODE_ZOOM) {
                    carousel
                        .markCurrentPage(page)
                        .scrollIntoView();
                }
            });
        }

        /**
         * @description Handle thumbs click to sync zoom and thumbs state
         * @param el - event source element
         * @param page - current page number
         */
        onThumbnailCarouselPageClicked(el : IRefElement, page: number): void {
            this.getById('imagesCarousel', (carousel: ICarousel) => carousel.scrollToPage(page));

            if (this.imageMode === IMAGE_MODE_ZOOM && this.gallery) {
                this.gallery.goTo(page);
            }

            this.getById('imagesThumbnails', (carousel: ICarousel) => {
                carousel
                    .markCurrentPage(page)
                    .scrollIntoView();
            });
        }

        /**
         * @description reset gallery state on PDP update
         */
        update(): void {
            this.getById('imagesThumbnails', (carousel: ICarousel) => {
                carousel
                    .update()
                    .scrollToPage(0)
                    .markCurrentPage(0)
                    .ref('self')
                    .addClass('m-inited');
            });

            this.getById('imagesCarousel', (carousel: ICarousel) => carousel
                .update()
                .scrollToPage(0));
        }

        /**
         * @description Get URL from data-original-src attribute.
         *  Load original image by URL. Get original size.
         *  The image element should have data-original-src attribute with original image URL.
         * @returns return new Promise with image sizes
         */
        getOriginalImageSize(): Promise<{ originalHeight: number, originalWidth: number }> {
            return new Promise((resolve) => {
                const imgCarousel = <ICarousel> this.getById('imagesCarousel', (carousel: ICarousel) => carousel);
                const predefinedSize = {
                    originalHeight: this.prefs().predefinedHeight,
                    originalWidth: this.prefs().predefinedWidth
                };

                if (!imgCarousel) {
                    resolve(predefinedSize);
                } else {
                    const firstImage = this.getCarouselImages()[0];

                    if (firstImage.dataset.originalSrc) {
                        const img = new Image();

                        img.addEventListener('load', () => resolve({ originalHeight: img.height, originalWidth: img.width }));
                        img.addEventListener('error', () => resolve(predefinedSize));
                        img.src = firstImage.dataset.originalSrc;
                    } else {
                        resolve(predefinedSize);
                    }
                }
            });
        }

        /**
         * @description Add focus trap functionality in the opened zoom dialog to prevent background elements focusing
         */
        addFocusTrap(): void {
            this.backFocusElement = document.activeElement instanceof HTMLElement ? document.activeElement : document.body;
            this.addFocusTraps();
            this.afterShowModal();
        }

        /**
         * @description Does all the work to init photoswipe and show it
         * @param {typeof import('photoswipe')} PhotoSwipe - PhotoSwipe library class
         * @param {typeof import('photoswipe/dist/photoswipe-ui-default')} PhotoSwipeUI - PhotoSwipeUI_Default library class
         * @param {{originalWidth: string, originalHeight:string}} originalImageSize Object with original image width and height
         */
        initAndShowZoom(PhotoSwipe, PhotoSwipeUI, { originalWidth, originalHeight }): void {
            const pswpElement = document.querySelectorAll('.pswp')[0];
            const imgCarousel = <ICarousel> this.getById('imagesCarousel', (carousel: ICarousel) => carousel);
            const thumbnailsCarousel = <ICarousel> this.getById('imagesThumbnails', (thumbnails: ICarousel) => thumbnails);

            if (!imgCarousel || !(pswpElement instanceof HTMLElement)) {
                return;
            }

            pswpElement.setAttribute('data-tau', 'zoom_dialog');

            const items = this.getCarouselImages().map((element: HTMLImageElement) => {
                return {
                    src: element.dataset.originalSrc || element.dataset.imgFallback,
                    w: originalWidth || element.naturalWidth,
                    h: originalHeight || element.naturalHeight
                };
            });

            const prefs = this.prefs();

            const options = {
                index: imgCarousel.getCurrentPageIndex(),
                barsSize: { top: prefs.barsSizeTop, bottom: prefs.barsSizeBottom },
                loop: prefs.zoomLoop,
                history: false,
                clickToCloseNonZoomable: prefs.zoomClickToCloseNonZoomable,
                closeElClasses: prefs.zoomCloseElClasses.split(','),
                closeOnScroll: prefs.closeOnScroll,
                showHideOpacity: prefs.showHideOpacity
            };

            const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI, items, options);

            gallery.listen('imageLoadComplete', () => {
                const accessibilityAlert = this.prefs().accessibilityAlerts.dialogContentLoaded;

                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit('alert.show', {
                    accessibilityAlert
                });

                this.toggleSpinner(false);
            });

            gallery.listen('close', () => this.onZoomClosed());

            gallery.listen('doubleTap', () => {
                const isZoomIn = gallery.getZoomLevel() === 1; // When double tap event reported we actually have previous state

                pswpElement.classList.toggle('pswp--zoomed-in', !isZoomIn);
            });

            gallery.listen('beforeChange', () => {
                const currentPage = gallery.getCurrentIndex();

                imgCarousel.scrollToPage(currentPage);

                if (thumbnailsCarousel) {
                    thumbnailsCarousel.markCurrentPage(currentPage).scrollIntoView();
                }
            });

            gallery.init();

            this.gallery = gallery;

            this.imageMode = IMAGE_MODE_ZOOM;

            if (thumbnailsCarousel) {
                this.toggleZoomState(true);
            }

            this.addGlobalDialogClass();

            if (thumbnailsCarousel) {
                thumbnailsCarousel.updateCarouselMetric();
                thumbnailsCarousel.updateCarouselState();
            }
        }

        /**
         * @description Shows spinner bar in widget once any update operations (server calls) are pending
         * @param {boolean} isBusy - show / hide spinner
         * @returns {void}
         */
        toggleSpinner(isBusy) {
            this.ref('zoomDialog').removeClass(this.prefs().classesLoading);

            if (this.longWaitingTimeout) {
                this.longWaitingTimeout();
            }

            if (isBusy) {
                // the spinner will be shown for an action that takes more than 1 second, which is recommended by UX practices
                this.longWaitingTimeout = timeout(() => {
                    this.ref('zoomDialog').addClass(this.prefs().classesLoading);
                }, 1000);
            }
        }

        /**
         * @description Click handler on image from large images carousel
         * @returns {Promise} - Promise that fulfills when all of the promises passed as an iterable have been fulfilled
         */
        loadPhotoswipeDependencies(): Promise<typeof import('photoswipe')> {
            return Promise.all([
                import(/* webpackChunkName: 'photoswipe' */'photoswipe'),
                import(/* webpackChunkName: 'photoswipe' */'photoswipe/dist/photoswipe-ui-default.js'),
                this.getOriginalImageSize()
            ]);
        }

        /**
         * @description Click handler on image from large images carousel
         * @listens dom#click
         */
        onImageCarouselPageClicked(): void {
            this.zoom();
        }

        /**
         * @description Click handler for "zoom" icon
         * @listens dom#click
         */
        openZoom() {
            this.zoom();
        }

        /**
         * @description Generic method to open zoom popup
         */
        zoom(): void {
            this.ref('zoomDialog').attr('aria-hidden', 'false');

            this.toggleSpinner(true);
            this.addFocusTrap();

            this.loadPhotoswipeDependencies()
                .then(([PhotoSwipe, PhotoSwipeUI, originalImageSize]) => {
                    this.initAndShowZoom(PhotoSwipe.default, PhotoSwipeUI.default, originalImageSize);
                });
        }

        /**
         * @description "Close" photoswipe popup icon click handler
         * @listens dom#click
         */
        closeZoom(): void {
            if (this.gallery) {
                this.gallery.close();

                const thumbnailsCarousel = <ICarousel> this.getById(
                    'imagesThumbnails',
                    (thumbnails: ICarousel) => thumbnails
                );

                if (thumbnailsCarousel) {
                    thumbnailsCarousel.updateCarouselMetric();
                    thumbnailsCarousel.updateCarouselState();
                }
            }

            const pswpElement = document.querySelectorAll('.pswp')[0];

            pswpElement?.removeAttribute('data-tau');
        }

        /**
         * @description Zoom Keydown Event handler
         * @listens dom#keydown
         * @param _ Source of keydown event
         * @param event  Event object
         */
        closeZoomKeydown(_: IRefElement, event: KeyboardEvent): void {
            if (event.keyCode === keyCode.ENTER || event.keyCode === keyCode.SPACE) {
                event.stopPropagation();
                event.preventDefault();
                this.closeZoom();
            }
        }

        /**
         * @description Sets image mode / do some DOM modifications after zoom closed
         */
        onZoomClosed(): void {
            this.toggleZoomState(false);

            this.imageMode = IMAGE_MODE_CAROUSEL;
            this.removeGlobalDialogClass();

            if (this.backFocusElement) {
                this.backFocusElement.focus();
                this.backFocusElement = undefined;
            }
        }

        /**
         * @description Add Global Dialog Class
         */
        addGlobalDialogClass(): void {
            const html = this.ref('html');

            if (!html.hasClass(this.prefs().classesGlobalDialog)) {
                html.addClass(this.prefs().classesGlobalDialog);
            }
        }

        /**
         * @description Remove Global Dialog Class
         */
        removeGlobalDialogClass(): void {
            this.ref('html').removeClass(this.prefs().classesGlobalDialog);
        }

        /**
         * @description Enter / space handler to initiate zoom popup
         * @listens dom#keydown
         * @param _el event source element
         * @param event event instance if DOM event
         */
        handleKeydown(_el: IRefElement, event: KeyboardEvent): void {
            if (event.keyCode === keyCode.ENTER || event.keyCode === keyCode.SPACE) {
                event.preventDefault();
                event.stopPropagation();
                this.zoom();
            }
        }

        /**
         * @description Get list of main carousel product images
         */
        getCarouselImages(): Array<HTMLImageElement> {
            const imgCarousel = <ICarousel> this.getById('imagesCarousel', (carousel: ICarousel) => carousel);
            const imagesContainer: HTMLElement | undefined = imgCarousel.ref(imgCarousel.prefs().elemCarouselTrack).get();

            return [].slice.call(imagesContainer?.querySelectorAll('img, iframe')) || [];
        }

        /**
         * @description Toggle Zoom state on Thumbnails carousel
         * @param state flag for toggle zoom state
         */
        toggleZoomState(state: boolean): void {
            const thumbnails = <ICarousel> this.getById('imagesThumbnails',
                (thumbnailsCarousel: ICarousel) => thumbnailsCarousel);

            thumbnails.ref('self').toggleClass(this.prefs().thumbnailsZoomClass, state);
        }
    }

    return ProductImages;
}
