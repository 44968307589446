/**
 * @param AjaxForm Base widget for extending
 * @returns TrackForm widget
 */
import { timeout } from 'widgets/toolbox/util';
type IInput = InstanceType<ReturnType<typeof import('widgets/forms/BasicInput').default>>;

export default function (AjaxForm: ReturnType<typeof import('widgets/forms/AjaxForm').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class TrackForm
     * @augments AjaxForm
     * @classdesc Represents TrackForm component with next features:
     *
     * @property {string} data-widget - Widget name `TrackForm`
     */
    class TrackForm extends AjaxForm {
        /**
         * @description Handles server response
         * @emits AjaxForm#submit
         * @param data Server JSON response once form submitted
         * @param [data.success] - If form submission was success
         * @param [data.redirectUrl] - if not empty - redirect to specified location should be executed
         * @param [data.error] - error messages/message from server
         * @param [data.fields] - form fields with errors
         */
        // eslint-disable-next-line sonarjs/cognitive-complexity
        onSubmitted(data): void {
            if (data.success && data.redirectUrl) {
                const orderID = this.items.find(item => item && item.id === 'dwfrm_trackOrder_orderNumber');

                this.eventBus().emit('account.order.track', { orderID: orderID });
                window.location.assign(data.redirectUrl);
            } else if (data.error) {
                if (data.redirectUrl) {
                    window.location.assign(data.redirectUrl);
                } else {
                    this.setError(Array.isArray(data.error) ? data.error.join('<br/>') : data.error);
                }
            } else if (data.fields) {
                Object.entries(data.fields).forEach(([name, errorMsg], index) => {
                    this.getById(name, (input: IInput) => {
                        input.setError(<string>errorMsg);

                        // Set focus to the first invalid input field
                        if (index === 0) {
                            input.setFocus();
                        }
                    });
                });
            } else {
                timeout(() => {
                    /**
                     * @description Event to submit AjaxForm
                     * @event AjaxForm#submit
                     */
                    this.emit('submit', data);
                });
            }
        }
    }

    return TrackForm;
}
